const minarat_donors = [
  'Hazrat Mirza Ghulam Ahmad Alahis Salaam',
  'Hazrat Maulvi Noorudin Khalifa tul Masih',
  'Hazrat Mirza Bashiruddin Mahmood Ahmad Khalifa tul Masih',
  'Hazrat Nusrat Jahan Begum',
  'Hazrat Sahibzada Mirza Bashir Ahmad Sahib',
  'Hazrat Nawab Muhammad Ali Khan Sahib',
  'Dr. Mir Muhammad Ismael Sahib',
  'Hafiz Roshan Ali Sahib',
  'Maulvi Zulfiqar Ali Khan Sahib',
  'Muhammad Hayat Khan Sahib Raees Hafizabad',
  'Ghulam Akbar Khan Sahib Hyderabad Deccan',
  'Babu Muhammad Afzal Sahib Wazeeristan',
  'Khan Bahadur Muhammad Ali Khan Kohat',
  'Chaudhry Naimatullah Khan Session Judge Dehli',
  'Rashida Begum Wife of Chaudhry Zafarullah Khan Sahib',
  'Akhwand Muhammad Afzal Khan Dera Ghazi Khan',
  'Daulat Khatoon Wife of Akhwand Sahib Mausoof',
  'Altaf Khan PasraKhund Sahib Mausoof',
  'Qureshi Mukhtar Ahmad Sahib Dehli',
  'Maryam Siddiqa Sahiba Dehli',
  'Qazi Syed Ameer Hussain Sahib Qadian',
  'Maulvi Mir Muhammad Saeed Sahib Hyderabad Deccan',
  'Munshi Shadi Khan Sahib Qadian',
  'Maulvi Muhammad Ali Sahib MA LLB',
  'Sheikh Niaz Ahmad Sahib Tajir Wazirabad',
  'Munshi Abdul Aziz Sahib Qadian',
  'Haji Seth Abdur Rehman Sahib Madrasi',
  'Seth Ali Muhammad Sahib Banglore',
  'Haji Seth Saleh Muhammad Madras',
  'Seth Ahmad Sahib Madras',
  'Seth Waalji Laalaji Sahib Madras',
  'Seth Zahoor Ali Sahib Hyderabad Deccan',
  'Mir Hamid Shah Sahib Sialkot',
  'Nawab Syed Muhammad Rizwi Sahib Bombay',
  'Mufti Muhammad Sadiq Sahib Qadian',
  'Mistri Muhammad Din Sahib Bhera',
  'Dr. Khalifa Rashidud din Sahib Qadian',
  'Khalifa Noordin Sahib Jamu',
  'Hafiz Muhammad Ishaq Sahib Hyderabad Deccan',
  'Syed Nasir Shah Sahib Qadian',
  'Syed Afzal Shah Sahib Qadian',
  'Syed Ghulam Ghaus Sahib Qadian',
  'Dr. Rehmat Ali Sahib Africa',
  'Babu Muhammad Afzal Editor Albadar Qadian',
  'Dr. Muhammad Ismail Khan Ghadhiaani',
  'Pir Barkat Ali Sahib Renmal',
  'Sheikh Ghulam Nabi Sethi Qadian',
  'Maulvi Sher Ali Sahib Qadian',
  'Maulvi Abdullah Sahib Sanori',
  'Mian Rahmatullah Sahib Sanori',
  'Mian Abdur Rahim Sahib Sanori',
  'Habibullah Khan Sahib Sanori',
  'Sufi Abdul Qadir Sahib BA',
  'Master Qadir Baksh Sahib Ludhiana',
  'Maulvi Abdur Rahim Dard Sahib',
  'Babu Ghulaab Khan Sialkot',
  'Mian Muhammad Din Sahib Sialkot',
  'Master Qamaruddin Sahib Ludhiana',
  'Maulvi Abdul Qadir Sahib Ludhiana',
  'Munshi Muhammad Akbar Sahib Patiala',
  'Hakeem Muhammad Hussain Qureshi Lahore',
  'Munshi Muhammad Jaa Sahib Ujaala',
  'Chaudhry Hakim Ali Qadian',
  'Mian Muhammad Siddique Seekhwaan',
  'Mian Imamuddin Seekhwaan',
  'Mian Jamaluddin Seekhwaan',
  'Mian Khairuddin Seekhwaan',
  'Maulvi Ghulam Imam Sahib Manipur Assam',
  'Sufi Nabi Baksh Sahib Clerk Mumbaasa',
  'Sheikh Abdur Rahman Sahib Clerk Mumbaasa',
  'Sheikh Karam Ilahee Sahib Wakeel Patiala',
  'Babu Roshan Din Sahib Sialkot',
  'Babu Shah Din Sahib Domeeli',
  'Haji Mullah Imam Baksh Sahib',
  'Seth Musa bin Usman Jaamnagar',
  'Dr. Raamind District Gardhwaal',
  'Sheikh Yaqoob Ali Sahib Qadian',
  'Wife of Sheikh Sahib Mausoof Qadian',
  'Mahmooda Akhtar Sheikh Sahiba Mausoof Qadian',
  'Sheikh Ghulam Ghaus Brother Sheikh Sahib Mausoof Qadian',
  'Maulvi Habeebur Rahman Sahib Hajipura',
  'Qazi Mir Hussain Ali Pur Multan',
  'Haji Ghulam Ahmad Sahib Karyaam',
  'Maulvi Muhammad Din Sahib Sareeh',
  'Wife of Maulvi Muhammad Din Sahib Sareeh',
  'Babu Jamaluddin Sahib Gujranwala',
  'Maulvi Shera Khan Hyderabad Deccan',
  'Seth Sheikh Hassan Sahib Yaadgeer',
  'Munshi Nadir Khan Sarkaali District Jehlum',
  'Mirza Muhammad Sadiq Sahib Lahore',
  'Hakeem Maulvi Fazluddin Qadian',
  'Munshi Rustam Ali Khan Court Inspector',
  'Mian Nabi Baksh Sahib Amritsar',
  'Mian Chiraag Din Sahib Lahore',
  'Maulvi Ghuaam Hussain Peshawar',
  'Sheikh Rahmatullah Tajir Lahore',
  'Sheikh Abdur Rahman BA Lahore',
  'Master Ghulam Muhmmad Sahib Sialkot',
  'Sheikh Fazal Haq Sahib Batala',
  'Sheikh Maula Baksh Pot Merchant Sialkot',
  'Sheikh Ghulam Haider Sahib Sialkot',
  'Maulvi Aziz Baksh Sahib Sialkot',
  'Muhammad Ismail Tailor Sialkot',
  'Fateh Muhammad Jaan Wazirabad',
  'Hakeem Mirza Khuda Baksh Sahib Lahore',
  'Munshi Mehr Din Sahib Patwari Kohliaan',
  'Hakim Muhammad Qasim Sahib Laala Musa',
  'Haji Mufti Gulzaar Muhammad Battala',
  'Mirza Hussain Baig Kharka District Gujraat',
  'Munshi Allah Ditta Sahib Sialkot',
  'Sufi Muhammad Yaqub Sahib Keedhi Afghanaa',
  'Babu Fakhar Din Supply Dipoo',
  'Dr. Attaullah Khan Dahram Court Baga',
  'Babu Nizaamuddin Mahilpur',
  'Babu Abdul Aziz Sahib Tailor Master Sialkot',
  'Babu Muhammad Wazir Khan Sahib Qadian',
  'Maulvi Qudratullah Sahib Sanori',
  'Mian Ala din Sahib Thaikriaan Rawalpindi',
  'Raja Ali Muhammad Sahib Afsar Maal Lahore',
  'Khan Bahadur Sheikh Muhammad Hussain Aligarh',
  'Dr. Syed Walayat Shah Sahib Africa',
  'Munshi Gohar Ali  Sahib Kotla Afghanaa',
  'Sheikh Mushtaq Hussain Gujranwala',
  'Khan Farzand Ali Sahib Qadian',
  'Khadija Begum Wife of Khan Sahib Mausoof Qadian',
  'Amatullah Begum Wife Second Mausoof',
  'Dr. Syed Abdul Sattar Shah Sahib',
  'Wife of Dr. Sahib Mausoof',
  'Dr. Hashmatullah Sahib Qadian',
  'Khan Sahib Barkat Ali Sahib of Shimla',
  'Maulvi Abdur Raheem Nayyar Sahib Qadian',
  'Jama\'at Ahmadiyya Lagos',
  'Maulvi Alamgir Khan Sahib Sindh',
  'Mistri Ali Baksh Sahib Fareedkot',
  'Wife of Mistri Sahib Mausoof',
  'Dr. Abdul Karim Sahib Gujranwala',
  'Sheikh Ali Azfar Sahib',
  'Munshi Muhammad Din Sahib Khaariaan',
  'Mian Ghulam Nabi Sahib Mahilpur',
  'Sheikh Fazal Ahmad Sahib Battala',
  'Wife of Sheikh Sahib Mausoof',
  'Dr. Syed Muhammad Hussain Dharamcourt Randhaawa',
  'Children of Dr. Sahib Mausoof',
  'Dr Fazulldin Sahib Africa',
  'Wife of Dr. Sahib Mausoof',
  'Children of Dr. Sahib Mausoof',
  'Sheikh Ahmad Allah Sahib Naushehra',
  'Qazi Abdullah Sahib Qadian',
  'Sufi Muhmmad Ali Janjua Sahib Jalalpur Jataan',
  'Babu Muhammad Abdullah Sahib Ferozpur',
  'Dr. Fazal Kareem Sahib Qadian',
  'Seth Abdullah Bhai Sahib Sikandarabad',
  'Wife of Abdullah Bhai Sahib Sikandarabad',
  'Seth Abdullah Aladin Sahib Sikandarabad',
  'Babu Muhammad Shafee Overseer Sahib Qadian',
  'Maulvi Muhammad Din Sahib HeadMaster Qadian',
  'Children of Maulvi Muhammad Din Sahib HeadMaster Qadian',
  'Hafiz Syed Abdul Waheed Mansuri Sahib',
  'Babu Ijaz Hussain Sahib Dehli',
  'Sheikh Abdur Rehman Sahib Qadiani',
  'Subedaar Ghulam Hussain Chak 181',
  'Master Muhammad Tufail Sahib Qadian',
  'Dr. Shah Nawaz Sahib Sialkot',
  'Hafiz Syed Abdul Majeed Sahib Mansoori',
  'Pir Manzoor Muhammad Sahib Qadian',
  'Munshi Gul Muhammad Sahib Tila Gang Kaimalpur',
  'Shiekh Niaz Ahmad Sahib Gujranwala',
  'Babu Ghulam Hussain Sahib Ludhianwi',
  'Seth Ali Muhammad Sahib Sikandarabad',
  'Fatima Begum Sahiba Bint Abdullah Bhai',
  'Babu Fazal Din Sahib Overseer Mardan',
  'Seth Ismail Aadam Sahib Bombay',
  'Dr. Ghulam Mustafa Sahib Lahore',
  'Syed Ghulam Hussain Sahib Rahtak',
  'Syeda Jamila Khatoon Sahiba Muzaffargarh',
  'Mater Muhammad Ibrahim Sahib Nankana',
  'Mian Muhammad Sharif Sahib Qasur',
  'Amtur Rahman Sahiba Bhera',
  'Nawab Muhammad Din Sahib Jhudhpur',
  'Malik Maula Baksh Sahib Amritsar',
  'Babu Sirajuddin Sahib',
  'Qureshi Muhammad Usman Sahib Karnaal',
  'Mirza Barkat Ali Sahib Abadaan',
  'Wife of Mirza Sahib Mausoof',
  'Ibrahim Yousaf Sahib Brauvli',
  'Babu Abdur Rehman Sahib Anbaala',
  'Haji Meeran Baksh Sahib Anbaala',
  'Mian Khuda Baksh Sahib Haandav',
  'Chaudhry Sadiq Ali Sahib Tehseeldaar',
  'Hakim Fazlur Rehman Sahib Missionary Africa',
  'Dr. Muhammad Ramzan Sahib Srigubandpura',
  'Chaudhry Muhammad Ismail Sahib Kherabajwa',
  'E.Malik Muzaffar Sahib Purbahaar',
  'Babu Ali Hassan Sahib Sanori',
  'Fehmida Begum Sahiba Shahjahaanpur',
  'Wife of Chaudhry Mubarak Ahmad Sahib Kohat',
  'Chaudhry Fateh Muhammad Sahib Sial',
  'Dr. Badaruddin Sahib Africa',
  'Mauvi Abdul Ghafoor Qadian',
  'Fatima uz Zuhra Sahiba Wife of Abdul Ghafoor Qadian',
  'Sardar Muhammad Ali Sahib Jodhakarnaana',
  'Maulvi Abdul Lateef Chittagong',
  'Syed Muhammad Lateef Sahib Chak Qaziaan',
  'Rasool Bibi Sahiba Talwandi Musa',
  'Maulvi Abdul Hakeem Sahib Kankipuri',
  'Babu Muhammad Wazi Sahib Lahore',
  'Maulvi Fazal Din Sahib Wakeel Qadian',
  'Chaudhry Noor Ahmad Muharar Qadian',
  'Sheikh Abdul Hameed Sahib of Shimla Qadian',
  'Zeenat Bibi Sahiba Wife of Bhai Mahmood Sahib',
  'Maulvi Abdul Mughni Sahib Nazir Baitul Mal Qadian',
];

export default minarat_donors;
